.job {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 5px;
    padding: 10px;

    h3 {
        margin-top: 0;
    }

    p {
        margin-bottom: 0;
    }
    
    &-image {
        flex-basis: 100px;
        display: inline-flex;
        align-items: center;

        @media all and (max-width: 480px) {
            margin: 10px auto 0 auto;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    &-description {
        flex-basis: calc(50% - 100px);
        padding-left: 20px;

        @media all and (max-width: 992px) {
            flex-basis: 50%;
        }

        @media all and (max-width: 768px) {
            flex-basis: calc(100% - 100px);
        }

        @media all and (max-width: 480px) {
            flex-basis: 100%;
            margin-top: 10px;
            text-align: center;
            padding-left: 0;
        }

        &-inner {
            display: flex;
            flex-wrap: wrap;

            @media all and (max-width: 480px) {
                justify-content: center;
            }

            > div {
                margin-right: 15px;
            }
        }
    }

    &-additional-info {
        display: inline-flex;
        flex-wrap: wrap;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        flex-grow: 1;

        @media all and (max-width: 768px) {
            flex-basis: 100%;
            margin-top: 15px;
        }

        @media all and (max-width: 480px) {
            align-items: center;
        }

        .badges {
            display: flex;
            flex-wrap: wrap;

            p {
                margin-left: 10px;
                padding: 5px;
                border-radius: 5px;
                background-color: #6264FC;
                color: #000;
            }
        }
    }
}