body {
  padding: 0;
  margin: 0;
  display: initial;
  color: #fff;
}

.container {
  max-width: 900px;
  margin: 0 auto;

  @media all and (max-width: 1200px) {
    padding: 0 15px;
  }
}