.news {
    border: 1px solid rgba(255, 255, 255, .4);
    border-radius: 25px;
    padding: 10px;
    margin-bottom: 20px;

    .news-header {
        height: 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &-image {
            height: 100%;
            flex-basis: 40px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &-user {
            flex-basis: calc(100% - 40px);
            padding-left: 10px;
        }
    }
}