// .event-container {
//     display: flex;
//     flex-wrap: wrap;

    .event-feed-with {
        flex-basis: 32%;
        margin: 10px 0;
        padding: 0 15px;
        color: #000;

        &-image {
            height: 200px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-description {
            padding: 20px 15px;
            background-color: #fff;

            * {
                color: #000;
            }
        }

        &-title {
            * {
                color: #000;
            }
            h3 {
                font-size: 16px;
                margin-top: 0;
            }
        }
    }
// }

.likes {
    display: flex;
    flex-wrap: wrap;


}