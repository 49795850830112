.discussion-container {
    .discussion-inner {
        display: flex;
        flex-wrap: wrap;

        &-img {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            flex-basis: 80px;

            svg {
                height: 2em;
            }
        }

        &-description {
            a {
                // color: gray;
                color: #fff;
                text-decoration: none;
                transition: .4s;

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}