* {
    box-sizing: border-box;
    color: #fff;
}

body {
    background-color:#003447;
}

.coins {
    font-size: 20px;
    svg {
        path {
            fill: #d4af37;
        }
    }
}

.rectangle {
    background-color: rgba(255, 255, 255, .15);
    position: absolute;

    &-1 {
        right: 70px;
        width: 150px;
        height: 250px;
        top: 300px;
        transform: translateY(-50%);
    }
}

#root {
    padding-top: 85px;

    > div {
        // position: relative;
    }
}

.circle {
    background-color: rgba(255, 255, 255, .05);
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: -100px;
    left: -100px;
    border-radius: 50%;

    &-2 {
        left: unset;
        bottom: unset;
        left: 50%;
        top: 180px;
        transform: translate(-50%, -50%);
    }
}

h1 {
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    font-size: 2em;
    line-height: 1;
    margin-top: 0;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        height: 8px;
        width: 90%;
        background-color: rgba(98, 100, 252, .5);
    }
}

.header {
    position: fixed;
    top: 0;
    background-color: rgba(98, 100, 252, 1);
    width: 100%;

    nav {
        position: relative;

        @media all and (max-width: 1200px) {
            padding: 0 15px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            @media all and (max-width: 768px) {
                position: absolute;
                left: 0;
                width: 100%;
                top: 30px;
                background-color: #003447;
                text-align: center;
            }
           
            &.show {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                @media all and (max-width: 768px) {
                    display: none;
                }
            }
            
            li {
                // margin: 0 15px;

                a {
                    display: inline-flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    width: 110px;
                    color: #fff;
                    transition: 0.4s;
                    padding: 0;

                    &:hover {
                        background-color: rgba(0, 0, 0, .5);
                    }
                }
            }
        }
    }

    .menu-btn {
        height: 30px;
        width: 30px;
        position: relative;
        padding: 5px;
        display: none;

        @media all and (max-width: 768px) {
            display: block;
        }

        span {
            width: calc(100% - 10px);
            height: 2px;
            background-color: #003447;
            border-radius: 5px;
            position: absolute;
            left: 5px;

            &:first-of-type {
                top: 5px;
            }

            &:nth-of-type(2) {
                top: 50%;
                transform: translateY(-50%);
            }

            &:last-of-type {
                bottom: 5px;
            }
        }
    }
}

.section-header {
    margin-bottom: 70px;
}