// .event-container {
//     display: flex;
//     flex-wrap: wrap;

    .event {
        flex-basis: 32%;
        // height: 300px;
        margin: 10px 0;
        padding: 0 15px;
        color: #000;
        

        &:nth-of-type(3n + 2) {
            margin: 10px 2%;
        }

        &-image {
            height: 200px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-description {
            padding: 20px 15px;
            background-color: #fff;

            * {
                color: #000;
            }
        }

        &-title {
            * {
                color: #000;
            }
            h3 {
                font-size: 16px;
                margin-top: 0;
            }
        }
    }
// }

.carousel {
    .slide {
        margin: 0 1%;
    }
}