.training {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 5px;
    padding: 10px;

    h3 {
        margin-top: 0;
    }

    p {
        margin-bottom: 0;
    }
    
    &-image {
        flex-basis: 100px;
        display: inline-flex;
        align-items: center;

        @media all and (max-width: 992px) {
            flex-basis: 50%;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    &-description {
        flex-basis: calc(50% - 100px);
        padding-left: 20px;

        @media all and (max-width: 992px) {
            flex-basis: 50%;
            display: inline-flex;
            flex-wrap: wrap;
            align-content: center;

            .training-title {
                flex-basis: 100%;
            }

            .training-firm {
                flex-basis: 100%;
            }
        }

        &-inner {
            display: flex;
            flex-wrap: wrap;

            > div {
                margin-right: 15px;
            }
        }
    }

    &-additional-info {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        flex-basis: 50%;
        padding-right: 10px;

        @media all and (max-width: 992px) {
            flex-basis: 100%;
        }

        &-desc {
            flex-basis: 70%;

            p {
                margin-top: 0;
            }
        }
        

        h3 {
            flex-basis: 20%;
            padding-left: 20px;
        }

        &-buttons {
            flex-basis: 30%;

            @media all and (max-width: 992px) {
                text-align: right;
            }

            a {
                padding: 5px 10px;
                border-radius: 5px;
                background-color: #6264FC;
                color: #000;
                transition: .4s;
                text-decoration: none;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}